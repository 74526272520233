// CheckmarkIcon Component
function CheckmarkIcon() {
    return (
      <svg width="100" height="100" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2" stroke="black" strokeWidth="2" fill="none" />
        <path d="M7 10.5L10 13.5L17 6.5" stroke="black" strokeWidth="2" fill="none">
          <animate attributeName="stroke-dasharray" from="0,22" to="22,0" dur="3s" begin="0s;2s" repeatCount="indefinite" />
        </path>
      </svg>
    );
  }
  
  // PlusIcon Component
  function PlusIcon() {
    return (
      <svg width="100" height="100" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
        <line x1="12" y1="4" x2="12" y2="8" stroke="black" stroke-width="2"/>
        <line x1="4" y1="12" x2="8" y2="12" stroke="black" stroke-width="2"/>
        <line x1="16" y1="12" x2="20" y2="12" stroke="black" stroke-width="2"/>
        <line x1="12" y1="16" x2="12" y2="20" stroke="black" stroke-width="2"/>
        <circle cx="12" cy="4" r="2" fill="black">
          <animate attributeName="r" values="2;3;2" dur="1s" repeatCount="indefinite"/>
        </circle>
        <circle cx="4" cy="12" r="2" fill="black">
          <animate attributeName="r" values="2;3;2" dur="1s" begin="0.25s" repeatCount="indefinite"/>
        </circle>
        <circle cx="12" cy="20" r="2" fill="black">
          <animate attributeName="r" values="2;3;2" dur="1s" begin="0.5s" repeatCount="indefinite"/>
        </circle>
        <circle cx="20" cy="12" r="2" fill="black">
          <animate attributeName="r" values="2;3;2" dur="1s" begin="0.75s" repeatCount="indefinite"/>
        </circle>
        <circle cx="12" cy="12" r="2.5" fill="black">
          <animate attributeName="r" values="2.5;4;2.5" dur="1s" begin="0s" repeatCount="indefinite"/>
        </circle>
      </svg>

    );
  }
  
  // ClockIcon Component
  function ClockIcon() {
    return (
      <svg width="100" height="100" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="9" stroke="black" strokeWidth="2" fill="none" />
        {/* Hour and minute hands */}
        <line x1="12" y1="12" x2="12" y2="7" stroke="black" strokeWidth="2" strokeLinecap="round">
          <animateTransform attributeName="transform" type="rotate" from="0 12 12" to="360 12 12" dur="360s" repeatCount="indefinite" />
        </line>
        <line x1="12" y1="12" x2="12" y2="5" stroke="black" strokeWidth="2" strokeLinecap="round">
          <animateTransform attributeName="transform" type="rotate" from="0 12 12" to="360 12 12" dur="30s" repeatCount="indefinite" />
        </line>
        <circle cx="12" cy="12" r="0.5" fill="black" />
      </svg>
    );
  }
  
  // CompassIcon Component
  function CompassIcon() {
    return (
      <svg width="100" height="100" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(12,12)">
          <circle cx="0" cy="0" r="6" fill="none" stroke="black" strokeWidth="2" />
          <path d="M0,-8 L0,-10 M0,8 L0,10 M-8,0 L-10,0 M8,0 L10,0" stroke="black" strokeWidth="2" />
          <path d="M5.65,-5.65 L7.07,-7.07 M5.65,5.65 L7.07,7.07 M-5.65,-5.65 L-7.07,-7.07 M-5.65,5.65 L-7.07,7.07" stroke="black" strokeWidth="2" />
          <animateTransform attributeName="transform" type="rotate" from="0" to="360" dur="10s" repeatCount="indefinite" />
        </g>
        <path d="M6,16 Q8,13 10,16 T14,16" fill="none" stroke="black" strokeWidth="2">
          <animate attributeName="stroke-dasharray" from="0,100" to="10,0" dur="3s" repeatCount="indefinite" />
        </path>
        <path d="M18,16 Q16,13 14,16 T10,16" fill="none" stroke="black" strokeWidth="2">
          <animate attributeName="stroke-dasharray" from="0,100" to="10,0" dur="3s" repeatCount="indefinite" />
        </path>
      </svg>
    );
  }
  
  export { CheckmarkIcon, PlusIcon, ClockIcon, CompassIcon };
  