import { CheckmarkIcon, PlusIcon, ClockIcon, CompassIcon } from './Icons';
import './IconTextGrid.css';

function IconTextGrid() {
  return (
    <section className="icon-text-grid">
      <div className="icon-text-item">
        <CheckmarkIcon />
        <p>Выполяем задачи на 100%</p>
      </div>
      <div className="icon-text-item">
        <PlusIcon />
        <p>Полная струризация разработки</p>
      </div>
      <div className="icon-text-item">
        <ClockIcon />
        <p>Своевременное выполнение работ</p>
      </div>
      <div className="icon-text-item">
        <CompassIcon />
        <p>Создание полноценного продукта</p>
      </div>
    </section>
  );
}

export default IconTextGrid;
